'use client';

import { createAdapterProvider, renderQueryString } from '../chunk-SDEJ2M24.js';
import mitt from 'mitt';
import { useState, useEffect } from 'react';

var emitter = mitt();
function updateUrl(search, options) {
  const url = new URL(location.href);
  url.search = renderQueryString(search);
  const method = options.history === "push" ? history.pushState : history.replaceState;
  method.call(history, history.state, "", url);
  emitter.emit("update", search);
}
function useNuqsReactAdapter() {
  const [searchParams, setSearchParams] = useState(() => {
    if (typeof location === "undefined") {
      return new URLSearchParams();
    }
    return new URLSearchParams(location.search);
  });
  useEffect(() => {
    const onPopState = () => {
      setSearchParams(new URLSearchParams(location.search));
    };
    emitter.on("update", setSearchParams);
    window.addEventListener("popstate", onPopState);
    return () => {
      emitter.off("update", setSearchParams);
      window.removeEventListener("popstate", onPopState);
    };
  }, []);
  return {
    searchParams,
    updateUrl
  };
}
var NuqsAdapter = createAdapterProvider(useNuqsReactAdapter);

export { NuqsAdapter };
